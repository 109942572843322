import { GetStaticProps } from 'next'
import { NextSeo } from 'next-seo'

import CallToActionSection from '@/components/call-to-action-section'
import Footer from '@/components/footer'
import { graphcms, IReview, REVIEW_QUERY } from '@/lib/hygraph'
import FeatureSection from '@/modules/landing-page/components/features-section'
import HeroSection from '@/modules/landing-page/components/hero-section'
import KeyFeaturesSection from '@/modules/landing-page/components/key-features'
import MetricsSection from '@/modules/landing-page/components/metrics-section'
import OurSolutionsSection from '@/modules/landing-page/components/our-solutions-section'
import TrustedBySection from '@/modules/landing-page/components/trusted-by-section'

type PageProps = {
  reviews: IReview[]
}

export default function Home({ reviews }: PageProps) {
  return (
    <>
      <NextSeo
        title="Mindsite | E-commerce Analytics Solution"
        description="Mindsite provides e-commerce analytics. Easily monitor key metrics from price to visibility in real time. Increase your online sales."
        canonical={`${
          process.env.NEXT_PUBLIC_BASE_URL || 'https://themindsite.com'
        }`}
      />

      <main>
        <HeroSection />
        <FeatureSection />
        <OurSolutionsSection />
        <TrustedBySection reviews={reviews} />
        <KeyFeaturesSection />
        <MetricsSection />
        <CallToActionSection
          title="Make your online sales more profitable with e-commerce analytics."
          description={[
            'Discover how our digital shelf analytics can give you a competitive edge. Request a demo today and take the first step toward e-commerce success.',
          ]}
        />
        <Footer />
      </main>
    </>
  )
}

export const getStaticProps: GetStaticProps<{
  reviews: IReview[]
}> = async () => {
  try {
    const reviews = (await graphcms
      .request(REVIEW_QUERY)
      .then((res: any) => res.reviews)) as IReview[]

    return { props: { reviews } }
  } catch (error) {
    console.error(error)
  }

  return {
    props: {
      reviews: [],
    },
    notFound: true,
    revalidate: 60 * 60, // 1 hour
  }
}
