import anime from 'animejs'
import Image from 'next/image'
import Link from 'next/link'
import { useInViewEffect } from 'react-hook-inview'

import BackgroundIconsAnimations from '@/components/background-icons-animations'

const HeroSection = () => {
  const imageContainerRef = useInViewEffect(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)

        anime({
          targets: '#summary-section .chart',
          translateY: [100, 0],
          easing: 'easeOutQuad',
          opacity: [0, 1],
          duration: 500,
          delay: anime.stagger(100),
        })
      }
    },
    { threshold: 0.2 }
  )

  const titleRef = useInViewEffect(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)

        anime
          .timeline({ loop: false })
          .add({
            targets: '#hero-section-description',
            opacity: [0, 1],
            duration: 1000,
            delay: 500,
            easing: 'easeOutExpo',
          })
          .add(
            {
              targets: '#hero-section-cta',
              scale: [0.9, 1],
              opacity: [0, 1],
              duration: 100,
              easing: 'easeOutQuad',
            },
            '-=1000'
          )
          .add(
            {
              targets: '#video-player',
              opacity: [0, 1],
              translateY: [100, 0],
              duration: 1000,
              easing: 'easeOutExpo',
            },
            '-=600'
          )
      }
    },
    { threshold: 0.2 }
  )

  return (
    <section
      id="hero-section"
      className="relative isolate mx-auto flex w-full max-w-container flex-col gap-12 overflow-hidden px-4 pb-24 sm:px-8 md:px-12 lg:px-20"
    >
      <BackgroundIconsAnimations />

      <div className="flex h-screen max-h-[900px] flex-col items-center justify-center gap-10">
        <div
          ref={titleRef}
          className="flex flex-col items-center justify-center gap-6"
        >
          <h1
            id="hero-section-title"
            className="max-w-4xl text-center text-2xl font-bold !leading-64 text-brand-800 sm:text-4xl md:text-5xl"
          >
            E-Commerce Analytics Solution <br /> for Profitable Growth
          </h1>

          <h2
            id="hero-section-description"
            className="max-w-3xl text-center text-2xl text-zinc-600 text-opacity-70 opacity-0 sm:text-lg md:text-xl"
          >
            Mindsite provides e-commerce analytics solutions in 20+ countries.
            We collect data from 300+ retailers and centralize key metrics on a
            single platform for quick, data-driven decisions and rapid growth.
          </h2>
        </div>

        <Link
          id="hero-section-cta"
          href="/contact-us"
          className="group flex w-full max-w-[200px] items-center justify-center rounded-full bg-brand-600 px-4 py-2 text-center text-lg text-slate-50 duration-200 hover:bg-brand-500 focus:bg-brand-500 active:bg-brand-700 md:px-6 md:py-3"
        >
          Request a Demo
        </Link>
      </div>

      <div
        ref={imageContainerRef}
        id="summary-section"
        className="relative mx-auto -mt-36 h-screen max-h-[650px] w-full max-w-container"
      >
        <div className="chart absolute left-[52%] top-2 aspect-[281/358] w-44 opacity-0 md:w-full lg:left-[63%]  lg:top-32 lg:w-full">
          <Image
            src="/images/solutions/benefits-summary/chart-1.svg"
            className="overflow-hidden rounded-xl shadow-2xl"
            alt="Chart 1"
            width={281}
            height={358}
          />
        </div>

        <div className="chart absolute left-[40%] top-[27%] aspect-[281/358]  w-48  opacity-0  md:w-full lg:left-[27%] lg:top-[15%] lg:w-full">
          <Image
            src="/images/solutions/benefits-summary/chart-3.svg"
            className="overflow-hidden rounded-xl shadow-2xl"
            alt="Chart 3"
            width={281}
            height={358}
          />
        </div>

        <div className="chart absolute left-[10%] top-[17%] aspect-[168/333] w-48 opacity-0  md:w-full lg:left-[71%] lg:top-[45%] lg:w-full">
          <Image
            src="/images/solutions/benefits-summary/chart-4.svg"
            className="overflow-hidden rounded-xl shadow-2xl"
            alt="Chart 4"
            width={281}
            height={358}
          />
        </div>

        <div className="chart absolute left-[52%] top-[59%] z-10 aspect-[281/358] w-48 opacity-0  md:z-0  md:w-full lg:left-[10%] lg:top-[44%] lg:z-0 lg:w-full">
          <Image
            src="/images/solutions/benefits-summary/chart-5.svg"
            className="overflow-hidden rounded-xl shadow-2xl"
            alt="Chart 5"
            width={281}
            height={358}
          />
        </div>

        <div className="chart absolute left-[10%] top-[67%] aspect-[216/238] w-48 opacity-0  md:w-full lg:left-[36%] lg:top-[44%] lg:w-full">
          <Image
            src="/images/solutions/benefits-summary/chart-6.svg"
            className="overflow-hidden rounded-xl shadow-2xl"
            alt="Chart 6"
            width={281}
            height={358}
          />
        </div>

        <div className="chart absolute left-[10%] top-[44%] aspect-[224/300] w-44 opacity-0  md:w-full lg:left-[52%] lg:top-[56%] lg:w-full">
          <Image
            src="/images/solutions/benefits-summary/chart-2.svg"
            className="overflow-hidden rounded-xl shadow-2xl"
            alt="Chart 2"
            width={281}
            height={358}
          />
        </div>
      </div>
    </section>
  )
}

export default HeroSection
