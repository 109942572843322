import anime from 'animejs'
import clsx from 'clsx'
import Image from 'next/image'

import AnimatedText from '@/components/animated-text'
import Slider, { SliderItem } from '@/components/slider'
import { IReview } from '@/lib/hygraph'

import { TRUSTEDBYLOGOS } from '../constants/trusted-by-logos'

type PageProps = {
  reviews: IReview[]
}

const TrustedBySection = ({ reviews }: PageProps) => {
  return (
    <section
      id="trusted-by-section"
      className="mx-auto flex w-full max-w-container flex-col items-center justify-between gap-y-32 overflow-hidden px-4 py-10 sm:px-8 md:px-12 lg:px-20"
    >
      <div
        className={clsx(
          'relative mx-auto flex max-w-container flex-col items-center justify-center gap-10'
        )}
      >
        <AnimatedText
          id="trusted-by-title"
          content="Trusted by the best."
          className="text-2xl/snug font-semibold text-green-500 sm:text-3xl/snug md:text-4xl/snug"
          onStarted={() => {
            anime({
              targets: '#trusted-by-section .logo-list img',
              translateY: [100, 0],
              opacity: [0, 1],
              skewY: [10, 0],
              duration: 200,
              delay: anime.stagger(50),
              easing: 'spring(1, 80, 15, 0)',
            })

            anime({
              targets: '#trusted-by-section-bg',
              translateY: [200, 0],
              translateX: {
                value: '-50%',
                duration: 0,
              },
              opacity: [0, 1],
              scale: [0, 2],
              duration: 2000,
              easing: 'easeOutQuad',
            })
          }}
        />

        <Image
          id="trusted-by-section-bg"
          src="/images/landing-page/trusted-by/bg.png"
          width={20}
          height={20}
          alt="Trusted by illustration"
          className="absolute inset-x-0 top-20 h-96 w-20 opacity-0 md:left-1/2 md:w-1/2"
        />

        <div className="logo-list flex w-full flex-wrap items-center justify-center gap-8 md:gap-16 lg:gap-10">
          {TRUSTEDBYLOGOS.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={logo.alt}
              className={clsx(
                'h-full max-h-6 max-w-[160px] object-contain opacity-0 mix-blend-difference sm:max-h-8 md:max-h-8',
                logo.className
              )}
            />
          ))}
        </div>
      </div>
      <Slider>
        {reviews.map(review => (
          <SliderItem
            key={review.name}
            className="mx-auto flex max-w-container flex-col items-center justify-center rounded-2xl bg-green-200"
          >
            <div className="flex w-full flex-col-reverse items-center justify-between overflow-hidden rounded-[10px] border border-transparent px-5 py-5 md:flex-row md:gap-x-10 md:px-20">
              <div className="flex gap-x-2 lg:max-w-3xl">
                <div>
                  <svg
                    width="22"
                    height="15"
                    viewBox="0 0 22 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.93326 14.5548H0.355469V9.98701C0.355469 8.1387 0.544239 6.68428 0.921781 5.62377C1.3173 4.54811 2.03643 3.58608 3.07916 2.73767C4.12189 1.88926 5.45228 1.22266 7.07031 0.737854L8.55351 3.37398C7.04334 3.79818 5.95567 4.38904 5.29047 5.14654C4.64326 5.90405 4.30167 6.91153 4.26572 8.169H7.93326V14.5548ZM20.5809 14.5548H13.0031V9.98701C13.0031 8.12354 13.1919 6.66156 13.5694 5.60105C13.9649 4.54054 14.6841 3.58608 15.7268 2.73767C16.7875 1.88926 18.1179 1.22266 19.7179 0.737854L21.2011 3.37398C19.691 3.79818 18.6033 4.38904 17.9381 5.14654C17.2909 5.90405 16.9493 6.91153 16.9134 8.169H20.5809V14.5548Z"
                      fill="#18181B"
                    />
                  </svg>
                </div>
                <div className="flex text-left text-sm text-zinc-600 md:text-lg lg:text-xl lg:leading-[30px]">
                  {review.review}
                </div>
              </div>
              <div className="flex max-w-full flex-col items-center gap-y-4">
                <div className="relative flex items-center justify-center">
                  <img
                    className="rounded-full border-2 border-white shadow-lg md:h-24 md:w-24 lg:h-[120px] lg:w-[120px]"
                    src={review.picture.url}
                    alt={review.picture.fileName}
                    width={96}
                    height={96}
                  />
                </div>
                <div className="flex flex-col items-center">
                  <div className="text-sm font-semibold text-zinc-900 md:text-base lg:text-xl">
                    {review.name}
                  </div>
                  <div className="text-center text-xs text-zinc-900 md:text-xs">
                    {review.position}
                  </div>
                </div>
                {review.logo ? (
                  <img
                    src={review.logo.url}
                    alt={review.logo.fileName}
                    width={160}
                    height={30}
                  />
                ) : null}
              </div>
            </div>
          </SliderItem>
        ))}
      </Slider>
    </section>
  )
}

export default TrustedBySection
