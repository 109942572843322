import anime from 'animejs'
import dynamic from 'next/dynamic'
import { ReactNode } from 'react'
import { useInViewEffect } from 'react-hook-inview'

const CountUp = dynamic(() => import('react-countup'), { ssr: false })

type Props = {
  value: ReactNode
  description: string
  title?: string
}

const MetricItem = ({ value, description, title }: Props) => {
  return (
    <div className="metric-item flex flex-col items-start gap-1 opacity-0 sm:gap-2 md:gap-3 lg:gap-3">
      <p
        style={{
          WebkitTextStroke: '1px #1E3A8A',
        }}
        className="text-5xl font-bold text-white sm:text-6xl lg:text-7xl"
      >
        {value}
      </p>
      <div className="flex flex-col gap-y-1">
        {title && (
          <p className="text-sm text-brand-900 sm:text-base md:text-xl lg:text-2xl">
            {title}
          </p>
        )}
        <p className="text-sm text-gray-400 sm:text-base md:text-lg lg:text-xl">
          {description}
        </p>
      </div>
    </div>
  )
}

const MetricsSection = () => {
  const sectionRef = useInViewEffect(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)

        anime({
          targets: '#metrics-section .metric-item',
          translateY: [100, 0],
          opacity: [0, 1],
          duration: 300,
          easing: 'spring(1, 80, 12, 0)',
          delay: anime.stagger(200),
        })
      }
    },
    { threshold: 0.5 }
  )

  return (
    <section
      ref={sectionRef}
      id="metrics-section"
      className="mx-auto flex max-w-container items-center justify-center px-4"
    >
      <div className="flex w-full flex-col items-start justify-start gap-8 px-4 py-24 sm:px-8 md:flex-row md:justify-between md:gap-4 md:px-12 lg:px-20">
        <div className="md:sticky md:top-24">
          <h2 className="max-w-lg text-2xl font-semibold text-brand-900 sm:text-3xl md:text-4xl lg:text-5xl">
            Rethink what's <br /> possible with <br /> analytics.
          </h2>
        </div>
        <div className="flex max-w-lg flex-none flex-col  items-start justify-end gap-8 sm:gap-10 md:flex-1 md:gap-16 lg:gap-10">
          <MetricItem
            value={<CountUp end={17000000} enableScrollSpy scrollSpyOnce />}
            title="Product Pages"
            description="Access daily data from millions of product pages to discover key market trends."
          />
          <MetricItem
            value={
              <CountUp end={300} suffix="+" enableScrollSpy scrollSpyOnce />
            }
            title="Retailer Sites"
            description="Get real-time insights from over 300 retailers for accurate market analysis."
          />
          <MetricItem
            value={<CountUp end={20} enableScrollSpy scrollSpyOnce />}
            title="Countries"
            description="Receive daily data from multiple countries of your choice for global strategies."
          />
        </div>
      </div>
    </section>
  )
}

export default MetricsSection
