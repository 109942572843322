import anime from 'animejs'
import clsx from 'clsx'
import Image from 'next/image'
import { useInViewEffect } from 'react-hook-inview'

const FeatureSection = () => {
  const sectionRef = useInViewEffect(
    ([entry], observer) => {
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)

        anime
          .timeline({ loop: false })
          .add({
            targets: '#features-section .card',
            translateY: [100, 0],
            opacity: [0, 1],
            duration: 500,
            easing: 'easeOutQuad',
            delay: anime.stagger(100),
          })
          .add(
            {
              targets: '#features-section .card .title',
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 500,
              easing: 'easeOutQuad',
              delay: anime.stagger(100),
            },
            '-=500'
          )
          .add(
            {
              targets: '#features-section .card .description',
              translateY: [100, 0],
              opacity: [0, 1],
              duration: 500,
              easing: 'easeOutQuad',
              delay: anime.stagger(100),
            },
            '-=500'
          )
      }
    },
    { threshold: 0.2 }
  )

  return (
    <section
      ref={sectionRef}
      id="features-section"
      className="flex w-full items-center justify-center overflow-hidden bg-orange-50 bg-opacity-30"
    >
      <div
        className={clsx(
          'mx-auto flex w-full max-w-container flex-col gap-10 px-4 py-24 sm:px-8 md:flex-row md:px-16 lg:px-20'
        )}
      >
        <div className="card flex flex-col items-center justify-center gap-10 opacity-0">
          <Image
            src="/images/landing-page/features-section/feature-1.svg"
            alt="Save your time icon"
            width={150}
            height={150}
          />
          <div className="flex flex-1 flex-shrink-0 flex-col items-center justify-center gap-3">
            <h2 className="title text-center text-2xl font-semibold text-brand-800 opacity-0">
              Increase Your Sales & Profitability
            </h2>
            <p className="description max-w-sm text-center text-xl text-zinc-500 opacity-0">
              Optimize pricing strategies, win the buy box, and enhance your
              market presence to drive revenue and profitability.
            </p>
          </div>
        </div>
        <div className="card flex flex-col items-center justify-center gap-10 opacity-0">
          <Image
            src="/images/landing-page/features-section/feature-2.svg"
            alt="Analyze easily icon"
            width={150}
            height={150}
          />
          <div className="flex flex-1 flex-shrink-0 flex-col items-center justify-center gap-3">
            <h2 className="title text-center text-2xl font-semibold text-brand-800 opacity-0">
              Elevate E-commerce Performance
            </h2>
            <p className="description max-w-sm text-center text-xl text-zinc-500 opacity-0">
              Boost search performance, achieve digital shelf optimization with
              real-time data, and seamlessly monitor your competitors.
            </p>
          </div>
        </div>
        <div className="card flex flex-col items-center justify-center gap-10 opacity-0">
          <Image
            src="/images/landing-page/features-section/feature-3.svg"
            alt="Take action on time icon"
            width={150}
            height={150}
          />
          <div className="flex flex-1 flex-shrink-0 flex-col items-center justify-center gap-3">
            <h2 className="title text-center text-2xl font-semibold text-brand-800 opacity-0">
              Save Time, Improve Efficiency
            </h2>
            <p className="description max-w-sm text-center text-xl text-zinc-500 opacity-0">
              Manage vast data on a single platform, track key metrics
              effortlessly, and respond instantly to changing market conditions.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeatureSection
