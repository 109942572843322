import anime from 'animejs'
import clsx from 'clsx'
import Link from 'next/link'
import { useCallback, useRef, useState } from 'react'
import { useInViewEffect } from 'react-hook-inview'

import SOLUTIONS from '@/modules/landing-page/constants/solutions'

const OurSolutionsSection = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(-1)
  const videoRef = useRef<HTMLVideoElement>(null)
  const mobileVideoRef = useRef<HTMLVideoElement>(null)

  const goToTab = useCallback((index: number) => {
    const largeScreen = window.matchMedia('(min-width: 1024px)')
    const currentVideo = largeScreen.matches
      ? videoRef.current
      : mobileVideoRef.current

    anime({
      targets: currentVideo,
      opacity: 0,
      scale: [1, 0.9],
      duration: 500,
      easing: 'linear',
      complete: () => {
        setCurrentTabIndex(index)
        anime({
          targets: currentVideo,
          opacity: 1,
          scale: [1.1, 1],
          duration: 500,
          easing: 'linear',
        })
      },
    })
  }, [])

  const sectionRef = useInViewEffect(([entry], observer) => {
    if (entry.isIntersecting) {
      observer.unobserve(entry.target)
      goToTab(0)
    }
  })

  return (
    <section
      ref={sectionRef}
      className="isolate mx-auto flex max-w-container flex-col gap-4 px-4 pt-24 sm:gap-12 md:mb-32 md:px-8 md:pt-32 lg:px-12"
    >
      <h2 className="text-center text-2xl font-semibold text-zinc-700 sm:text-3xl md:text-4xl">
        Our Solutions
      </h2>

      <div className="flex items-start justify-start gap-12">
        <div className="flex max-w-fit flex-shrink-0 flex-col lg:max-w-md">
          {SOLUTIONS.map((tab, index) => (
            <div key={index} className="relative flex gap-4 pb-4">
              {index !== SOLUTIONS.length - 1 && (
                <div className="absolute left-6 top-4 -z-10 h-full w-[1px] flex-shrink-0 -translate-x-1/2 bg-zinc-200"></div>
              )}

              {index !== SOLUTIONS.length - 1 && currentTabIndex === index && (
                <div
                  className={clsx(
                    'active-tab-line absolute left-6 top-4 -z-10 w-1 flex-shrink-0 -translate-x-1/2 rounded-3xl bg-brand-600'
                  )}
                ></div>
              )}

              <div className="flex h-full w-12 flex-shrink-0 justify-center">
                <div
                  className={clsx(
                    'flex flex-shrink-0 items-center justify-center rounded-full ring-white duration-300',
                    currentTabIndex === index
                      ? 'h-12 w-12 bg-brand-100 ring-8'
                      : 'mt-5 h-2.5 w-2.5 bg-zinc-200 ring-4'
                  )}
                >
                  <div
                    className={clsx(
                      'z-10 delay-100 duration-300',
                      currentTabIndex === index
                        ? 'opacity-1 scale-100'
                        : 'scale-0 opacity-0'
                    )}
                  >
                    {tab.Icon && <tab.Icon />}
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex h-12 items-center justify-start">
                  <h2
                    onClick={() => {
                      goToTab(index)
                    }}
                    tabIndex={0}
                    className={clsx(
                      'cursor-pointer duration-300',
                      currentTabIndex === index
                        ? 'text-2xl text-brand-600'
                        : 'text-xl text-zinc-500'
                    )}
                  >
                    {tab.title}
                  </h2>
                </div>

                <div
                  className={clsx(
                    'flex flex-col gap-5 overflow-hidden duration-300',
                    currentTabIndex === index ? 'h-max' : 'h-0'
                  )}
                >
                  <p className="text-md text-zinc-600">{tab.description[0]}</p>

                  <Link
                    href={tab.url}
                    className="text-md text-brand-600"
                    title={tab.title}
                  >
                    Discover more
                  </Link>

                  <div className="flex w-full flex-1 items-center lg:hidden">
                    <video
                      ref={mobileVideoRef}
                      id="mobile-video-player"
                      playsInline
                      controls={false}
                      autoPlay
                      muted
                      className="aspect-16/10 overflow-hidden rounded border border-zinc-100"
                      src={tab.videoUrl}
                      poster={tab.videoPosterUrl}
                      preload="metadata"
                      onTimeUpdate={e => {
                        // @ts-ignore
                        if (isNaN(e.target.duration)) {
                          return
                        }
                      }}
                      onPlay={e => {
                        const currentTabLine =
                          document.querySelector('.active-tab-line')

                        anime({
                          targets: currentTabLine,
                          height: '100%',
                          // @ts-ignore
                          duration: e.target.duration * 1000,
                          easing: 'linear',
                        })
                      }}
                      onEnded={() => {
                        goToTab((currentTabIndex + 1) % SOLUTIONS.length)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="hidden min-h-[520px] flex-1 items-center lg:flex">
          {SOLUTIONS[currentTabIndex]?.videoUrl && (
            <video
              ref={videoRef}
              id="video-player"
              playsInline
              controls={false}
              autoPlay={true}
              preload="metadata"
              muted
              className="aspect-16/10 overflow-hidden rounded-xl shadow-xl"
              src={SOLUTIONS[currentTabIndex].videoUrl}
              poster="/images/landing-page/our-solutions-section/fallback.png"
              onTimeUpdate={e => {
                // @ts-ignore
                if (isNaN(e.target.duration)) {
                  return
                }
              }}
              onPlay={e => {
                const currentTabLine =
                  document.querySelector('.active-tab-line')

                anime({
                  targets: currentTabLine,
                  height: '100%',
                  // @ts-ignore
                  duration: e.target.duration * 1000,
                  easing: 'linear',
                })
              }}
              onEnded={() => {
                goToTab((currentTabIndex + 1) % SOLUTIONS.length)
              }}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default OurSolutionsSection
