import anime from 'animejs'
import clsx from 'clsx'
import Image from 'next/image'
import { useCallback, useState } from 'react'

const keyFeatures = [
  {
    title: 'Customizable Dashboards',
    description: [
      'Create widgets customized for your digital shelf needs.',
      'Display key data points in simple visuals. Understand complex data easily with a clear visual representation.',
    ],
    image: '/images/landing-page/key-features/dashboard.png',
  },
  {
    title: 'Alerts',
    description: [
      'Set up custom alerts for metric fluctuations. ',
      'Receive instant email notifications about crucial events. Take prompt action with timely information in hand.',
    ],
    image: '/images/landing-page/key-features/alerts.png',
  },
  {
    title: 'Scorecard',
    description: [
      'Utilize our tool to automatically combine metrics and dimensions.',
      'Assign weights based on your business priorities and generate an overall score. ',
    ],
    image: '/images/landing-page/key-features/scorecard.png',
  },
  {
    title: 'Customizable Reports',
    description: [
      'Create reports based on your requirements.',
      'Choose your metrics and visualization styles. Automate reporting and share or export findings seamlessly.',
    ],
    image: '/images/landing-page/key-features/reports.png',
  },
]

const KeyFeaturesSection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const goToTab = useCallback(
    (index: number) => {
      if (index === selectedIndex) return

      anime({
        targets: '#key-feature-image',
        opacity: 0,
        scale: [1, 0.95],
        duration: 100,
        easing: 'linear',
        complete: () => {
          setSelectedIndex(index)

          anime({
            targets: '#key-feature-image',
            opacity: 1,
            scale: [1.05, 1],
            duration: 200,
            easing: 'linear',
          })
        },
      })
    },
    [selectedIndex]
  )

  return (
    <section className="mx-auto mb-24 flex w-full max-w-container flex-col gap-4 overflow-hidden px-4 pt-24 sm:gap-8 md:mb-32 md:gap-8 md:px-8 md:pt-32 lg:px-12">
      <h2 className="text-center text-xl font-semibold text-zinc-700 sm:text-2xl md:text-3xl lg:text-4xl">
        Mindsite Key Features
      </h2>

      <div className="flex flex-1 flex-col gap-4">
        <div className="flex flex-col justify-center gap-2 md:flex-row md:gap-12">
          {keyFeatures.map((keyFeature, index) => (
            <button
              key={keyFeature.title}
              className={clsx(
                'text-md border-b-2 py-2 font-medium duration-200 md:text-xl',
                selectedIndex === index
                  ? 'border-brand-600 text-brand-600'
                  : 'border-transparent text-zinc-600 hover:text-brand-500 active:text-brand-600'
              )}
              onClick={() => goToTab(index)}
            >
              {keyFeature.title}
            </button>
          ))}
        </div>
        <div className="flex flex-col items-center gap-6 md:p-4">
          <div
            id="key-feature-image"
            className="relative flex aspect-16/10 max-h-[50vh] w-full max-w-3xl flex-1"
          >
            {keyFeatures.map((keyFeature, index) => (
              <Image
                key={keyFeature.title}
                src={keyFeature.image}
                alt={keyFeature.title}
                fill
                className={clsx(
                  'rounded-xl object-contain shadow-lg',
                  selectedIndex === index ? 'opacity-100' : 'opacity-0'
                )}
              />
            ))}
          </div>

          <div className="flex max-w-4xl flex-1 flex-col gap-2">
            {keyFeatures[selectedIndex].description.map(
              (description, index) => (
                <p
                  key={index}
                  className="text-center text-base text-zinc-500 md:text-lg lg:text-xl"
                >
                  {description}
                </p>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default KeyFeaturesSection
